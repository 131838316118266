import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { IndustryBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper } from '@raysync/common/components'

import Layout from '../../components/layout'

import { SectionDesc, FeatureSection, SolutionSection, IndustryDivider } from './atoms'
import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/solutions/industry/manufacturing/feature1'
import Feature2 from '@raysync/common/components/svg/solutions/industry/manufacturing/feature2'
import Feature3 from '@raysync/common/components/svg/solutions/industry/manufacturing/feature3'
import Feature4 from '@raysync/common/components/svg/solutions/industry/manufacturing/feature4'

class Manufacturing extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('manufacturing.feature.item1.title'),
        desc: intl.get('manufacturing.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('manufacturing.feature.item2.title'),
        desc: intl.get('manufacturing.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('manufacturing.feature.item3.title'),
        desc: intl.get('manufacturing.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('manufacturing.feature.item4.title'),
        desc: intl.get('manufacturing.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
  }
  render() {
    const { location, data } = this.props
    const { featureList } = this.state
    return (
      <Layout pageType='manufacturing' location={location}>
        <Swiper>
          <IndustryBanner type='manufacturing' data={data.manufacturingBanner.childImageSharp.fluid} />
        </Swiper>
        <FeatureSection>
          <CommonTitle>{intl.get('manufacturing.challenge.title')}</CommonTitle>
          <SectionDesc top={32} bottom={22}>
            {intl.get('manufacturing.challenge.desc1')}
          </SectionDesc>
          <SectionDesc>{intl.get('manufacturing.challenge.desc2')}</SectionDesc>
          <SectionDesc>{intl.get('manufacturing.challenge.desc3')}</SectionDesc>
          <SectionDesc>{intl.get('manufacturing.challenge.desc4')}</SectionDesc>
          <SectionDesc>{intl.get('manufacturing.challenge.desc5')}</SectionDesc>
          <SectionDesc>{intl.get('manufacturing.challenge.desc6')}</SectionDesc>
          <SectionDesc>{intl.get('manufacturing.challenge.desc7')}</SectionDesc>
          <SectionDesc>{intl.get('manufacturing.challenge.desc8')}</SectionDesc>
          <SectionDesc bottom={32}>{intl.get('manufacturing.challenge.desc9')}</SectionDesc>
          <IndustryDivider />
        </FeatureSection>
        <SolutionSection style={{ pmanufacturingdingTop: '24px' }}>
          <CommonTitle>{intl.get('manufacturing.solution.title')}</CommonTitle>
          <SectionDesc top={16} bottom={36}>
            {intl.get('manufacturing.solution.desc')}
          </SectionDesc>
          <List data={featureList} />
        </SolutionSection>
        <Swiper>
          <InviteBanner type='manufacturing' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default Manufacturing

export const query = graphql`
  query {
    manufacturingBanner: file(relativePath: { regex: "/industry/manufacturing_banner/" }) {
      ...fluidImage
    }
  }
`
