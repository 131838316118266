import React from 'react'

const SvgFeature2 = props => (
  <svg id='feature2_svg__tuceng_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature2_svg__st0{fill:#334966}'}</style>
    <g id='feature2_svg__\u5F39\u6027\u8BA1\u7B97-48x48'>
      <path
        id='feature2_svg__Fill-1_3_'
        className='feature2_svg__st0'
        d='M25 1C11.74 1 1 11.74 1 25s10.74 24 24 24 24-10.74 24-24S38.26 1 25 1m0 2c12.13 0 22 9.87 22 22s-9.87 22-22 22S3 37.13 3 25 12.87 3 25 3'
      />
      <path id='feature2_svg__Polygon-3' fill='#fd5c1f' d='M25 12l11 6.5v13L25 38l-11-6.5v-13z' />
      <path
        id='feature2_svg__Fill-1_2_'
        className='feature2_svg__st0'
        d='M25 11l-6 3.6 1.94 1.17L24 13.93V19h2v-5.07l3.06 1.84L31 14.6z'
      />
      <path
        id='feature2_svg__Fill-2_3_'
        className='feature2_svg__st0'
        d='M35.28 27.2v2.46l-4.2-2.42L30 28.93l4.51 2.6-3.23 1.94v2.33l6-3.6V26z'
      />
      <path
        id='feature2_svg__Fill-3_2_'
        className='feature2_svg__st0'
        d='M13 32.2l6 3.6v-2.33l-3.23-1.94 4.51-2.6-1.08-1.69-4.2 2.42V27.2L13 26z'
      />
    </g>
  </svg>
)

export default SvgFeature2
